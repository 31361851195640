import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  TreeSelect,
  Alert,
  Upload,
  Button,
  Select,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import { getList } from "../../Redux/Page/actions";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";
import { BASE_URL } from "../../Redux/urls";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const ArticleForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getList,
  list,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [page_description, setPageDescription] = useState(
    isUpdateForm && data.dataById?.data.page_description
  );
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.featured &&
          data.dataById.data.featured.full_path
      : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);
  const [bannerImage, setBannerImage] = useState();
  const [has_update_form_values, setUpdateFormValues] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading || list.loading;

  const loadArticle = async () => {
    await getList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!list.data) {
      loadArticle();
    }
  }, [list.data]);

  useEffect(() => {
    const alert = document.querySelector('.custom-alert')
      setTimeout(() => {
        alert.classList.add('fadeout')
      }, 5000)
  
  }, [selectedForm.error])

  const articleListOptions = list.data?.data?.map((itm) => {
    return {
      value: itm.id,
      label: itm.page_title,
      ...(itm.children?.length !== 0 && {
        children: itm.children?.map((a) => {
          return {
            value: a.id,
            label: a.page_title,
            ...(a.children?.length !== 0 && {
              children: a.children?.map((b) => {
                return {
                  value: b.id,
                  label: b.page_title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const clearForm = () => {
    form.resetFields();
    setPageDescription("");
    setFeaturedImage("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      page_title: data.dataById?.data.page_title,
      page_key: data.dataById?.data.page_key,
      url_title: data.dataById?.data.url_title,
      parent_id: data.dataById?.data.parent_id,
      page_description: data.dataById?.data.page_description,
      slug: data.dataById?.data.urlinfo?.url_slug,
      publish_status: data.dataById?.data.publish_status === 1 ? true : false,
      is_featured: data.dataById?.data.is_featured === 1 ? true : false,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.thumb_path,
      featured_alt: data.dataById?.data.featured?.alt_text,
      featured_caption: data.dataById?.data.featured?.caption,
      banner: data.dataById?.data.banner?.banner_path,
      banner_alt: data.dataById?.data.banner?.banner_alt,
      banner_caption: data.dataById?.data.banner?.banner_caption,
    });
  }
  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData
              ).then(() => {
                loadArticle();
              });
            });
        } else {
          createEntity(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData
          ).then(() => {
            loadArticle();
          });
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                true,
                history
              );
            });
        } else {
          createEntity(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  publish_status: values.publish_status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById();
                loadArticle();
              });
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              publish_status: values.publish_status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
            loadArticle();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const actionBarOptions = {
    titleToAdd: "Add Article",
    titleToUpdate: "Update Article",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Article",
    taskCompleteAddMessage: "Article is added!",
    taskCompleteUpdateMessage: "Article is updated!",
    discardLink: "articles",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="page_title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                    { min: 3, max: 255, message: "Invalid title" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 2, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({ slug: slugify(e.target.value) })
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Parent"
                  name="parent_id"
                  rules={[
                    {
                      required: true,
                      message: "Parent is required",
                    },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    style={{ width: "100%" }}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                  >
                    <TreeNode value={0} title="No Parent" />
                    {articleListOptions?.map((parent) => {
                      const { value, label, children } = parent;
                      return (
                        <TreeNode key={value} value={value} title={label}>
                          {children?.length !== 0 &&
                            children?.map((child) => {
                              const { value, label, children } = child;
                              return (
                                <TreeNode
                                  key={value}
                                  value={value}
                                  title={label}
                                >
                                  {children?.length !== 0 &&
                                    children?.map((gChild) => {
                                      const { value, label } = gChild;
                                      return (
                                        <TreeNode
                                          key={value}
                                          value={value}
                                          title={label}
                                        />
                                      );
                                    })}
                                </TreeNode>
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                  </TreeSelect>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Page Key"
                  name="page_key"
                  rules={[
                    {
                      required: true,
                      message: "key is required",
                    },
                  ]}
                >
                  <Select>
                    <Option value="defaultpage">Default Page</Option>
                    <Option value="homepage">Home/Landing Page</Option>
                    <Option value="aboutpage">About Page</Option>
                    <Option value="blogpage">Blog Page</Option>
                    <Option value="testimonialpage">Testimonial Page</Option>
                    <Option value="teampage">Team Page</Option>
                    <Option value="faqpage">Faq Page</Option>
                    <Option value="customizetrippage">
                      Customize Trip Page
                    </Option>
                    <Option value="contactpage">Contact Page</Option>
                    <Option value="bookingpage">Booking Page</Option>
                    <Option value="authorpage">Author Page</Option>
                    <Option value="thankyoupage">Thank You Page</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="publish_status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={16}>
                <Form.Item label="Description" name="page_description">
                  <TextEditor
                    value={page_description}
                    initialValue={isUpdateForm && page_description}
                    onEditorChange={(e) => {
                      setPageDescription(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8}>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      className="mb-0"
                      label="Meta Description"
                      name="meta_description"
                    >
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={10}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Featured Image"
                        image={
                          featured_image ||
                          (isUpdateForm &&
                            data.dataById?.data.featured?.full_path)
                        }
                        height={process.env.REACT_APP_ARTICLE_THUMBINAL_HEIGHT}
                        width={process.env.REACT_APP_ARTICLE_THUMBINAL_WIDTH}
                        setImage={(image_data) => {
                          setFeaturedImage(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {featured_image && image_crop_open && (
                        <ImageCrop
                          height={
                            process.env.REACT_APP_ARTICLE_THUMBINAL_HEIGHT
                          }
                          width={process.env.REACT_APP_ARTICLE_THUMBINAL_WIDTH}
                          image={featured_image}
                          setCroppedImage={setFeaturedImage}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="featured_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="featured_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} xl={14}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item name="bannerImage" style={{ marginBottom: "0" }}>
                      <Upload
                        listType="picture-card"
                        maxCount={1}
                        name="tet"
                        beforeUpload={(file) => {
                          return false;
                        }}
                        onChange={(file) => setBannerImage(file)}
                      >
                        {isUpdateForm &&
                        data.dataById?.data.banner?.full_path &&
                        !bannerImage ? (
                          <img
                            src={
                              BASE_URL + data.dataById?.data.banner?.full_path
                            }
                          />
                        ) : (
                          <UploadOutlined style={{ fontSize: "27px" }} />
                        )}
                      </Upload>
                      <span className="text-muted">
                        Size should be 1920x750
                      </span>
                    </Form.Item>
                    <Form.Item
                      label="Alt Text"
                      name="banner_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="banner_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              {/* <Col className="gutter-row banner-image" xs={24} xl={14}>
              <Form.List name="banners" className="bg-white">
                {(fields, { add, remove }) => (
                  <>
                    <h6>Banner Images</h6>
                    {fields.map((field, index) => (
                      <div
                        key={field.key}
                        className="card bg-white"
                        align="baseline"
                      >
                        <div class="card-body">
                          <label className="form-label">
                            Banner #{index + 1}{" "}
                            <span
                              class="btn-delate"
                              onClick={() => remove(field.name)}
                            >
                              Delete
                            </span>
                          </label>

                          <Row>
                            <Col xs={24} lg={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_image"]}
                              >
                                <div class="input-group">
                                  <input type="file" />
                                </div>
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={6}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_alt"]}
                              >
                                <Input placeholder="Alt Text" />
                              </Form.Item>
                            </Col>

                            <Col xs={24} lg={12}>
                              <Form.Item
                                {...field}
                                name={[field.name, "banner_caption"]}
                              >
                                <Input placeholder="banner caption" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Banner
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col> */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
