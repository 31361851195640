import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Alert,
  InputNumber,
  Upload,
  Switch,
  Spin,
} from "antd";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";

import { getList as getPackageList } from "../../Redux/Package/actions";

//import "antd/dist/antd.css";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import DashboardActionBar from "../DashboardActionBar";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const ItineraryForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageListData,
}) => {
  const history = useHistory();
  const tokenData = loginData.data;
  const [description, setDescription] = useState(
    isUpdateForm && data.dataById?.data.description
  );
  const [hidden, setHidden] = useState(false);

  console.log(data);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || packageListData.loading;

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageListData.data) {
      loadPackages();
    }
  }, [packageListData.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const packageListOptions = packageListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
            description,
          },
          tokenData,
          params.id
        ).then(() => {
          loadEntityById();
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
  };
  if (isUpdateForm) {
    const itinerariesDetails = data.dataById?.data.details?.map((e) => {
      return {
        ...e,
        day: e.itinerary_day,
        description: e.itinerary_description,
        title: e.itinerary_title,
      };
    });
    setFieldsValue({
      itinerary_title: data.dataById?.data.itinerary_title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      package_id: data.dataById?.data.package_id,
      status: data.dataById?.data.status == 1 ? true : false,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      itineraries: itinerariesDetails,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Itinerary",
    titleToUpdate: "Update Itinerary",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Itinerary",
    taskCompleteAddMessage: "Itinerary is added!",
    taskCompleteUpdateMessage: "Itinerary is updated!",
    discardLink: "itineraries",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="itinerary_title"
                  rules={[
                    {
                      required: true,
                      message: "Itinerary title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Itinerary title" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid package title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({ slug: slugify(e.target.value) })
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Select a package"
                  name="package_id"
                  rules={[
                    {
                      required: true,
                      message: "Package field is required",
                    },
                  ]}
                >
                  <Select
                    onSelect={(e) => e !== null && setHidden(true)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {packageListOptions?.map((itm) => {
                      return (
                        <Option key={itm.value} value={itm.value}>
                          {itm.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={16}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            {form.getFieldValue("package_id") !== undefined && (
              <Form.List name="itineraries">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key} className="itineraries">
                        <h6 className="form-label">
                          Itinerary #{index + 1}{" "}
                          {index >= 1 && (
                            <span
                              class="btn-delate"
                              onClick={() => remove(field.name)}
                            >
                              Delete
                            </span>
                          )}
                        </h6>
                        <div class="common-module bg-white">
                          <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                            <Col className="gutter-row" xs={24} sm={4} xl={3}>
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_day"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field is required",
                                  },
                                ]}
                              >
                                <Input placeholder="Day" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={20} xl={21}>
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_title"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Title is required",
                                  },
                                ]}
                              >
                                <Input placeholder="Itinerary title (Outline)*" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "itinerary_description"]}
                              >
                                <TextEditor
                                  simpleTollbar
                                  inline
                                  placeholder="Itinerary description"
                                />
                              </Form.Item>

                              {isUpdateForm && (
                                <Form.Item
                                  hidden
                                  {...field}
                                  label="&nbsp;"
                                  initialValue={0}
                                  name={[field.name, "id"]}
                                >
                                  <InputNumber />
                                </Form.Item>
                              )}

                              {/* <Form.List {...field} name={[field.name, "facts"]}>
                              {(fields) => (
                                <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                                  {fields.map((field, index) => (
                                    <>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[0][fact_title]"]}
                                          initialValue="Distance"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Distance"
                                          {...field}
                                          name={[field.name, "facts[0][fact_value]"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[1][fact_title]"]}
                                          initialValue="Highest Altitude"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Highest Altitude"
                                          {...field}
                                          name={[field.name, "facts[1][fact_value]"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[2][fact_title]"]}
                                          initialValue="Duration"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Duration"
                                          {...field}
                                          name={[field.name, "facts[2][fact_value]"]}
                                        >
                                          <InputNumber />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[3][fact_title]"]}
                                          initialValue="Accomodation"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Accomodation"
                                          {...field}
                                          name={[field.name, "facts[3][fact_value]"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[4][fact_title]"]}
                                          initialValue="Place"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Place"
                                          {...field}
                                          name={[field.name, "facts[4][fact_value]"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} xl={8}>
                                        <Form.Item
                                          hidden
                                          {...field}
                                          name={[field.name, "facts[5][fact_title]"]}
                                          initialValue="Food"
                                        >
                                          <Input readOnly />
                                        </Form.Item>
                                        <Form.Item
                                          label="Food"
                                          {...field}
                                          name={[field.name, "facts[5][fact_value]"]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>

                                    </>
                                  )
                                  )}
                                </Row>
                              )}
                            </Form.List> */}

                              {isUpdateForm && (
                                <Form.Item
                                  {...field}
                                  name={[field.name, "gallery"]}
                                  className="mb-0"
                                >
                                  <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                    multiple
                                    //defaultFileList={[...fileList]}
                                    className="upload-list-inline"
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Upload Itinerary Gallery
                                    </Button>
                                  </Upload>
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        className="rounded"
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Itinerary
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageListData: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(getPackageList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryForm);
